import * as THREE from "three";
import {scene} from "./scene";
import {cameraControls} from "./controls/cameraControls";
import {Settings, CameraControlsType} from "../../settings";

/**
 * Camera & Controls
 */
// Base camera
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 100);
camera.layers.enableAll();
scene.add(camera);

// Update Camera
function initializeCamera() {
    if(Settings.cameraControlsType == CameraControlsType.orbitControls) {
        camera.fov = 75;
        camera.position.x = 0;
        camera.position.y = 7;
        camera.position.z = 5;
        camera.rotation.set(90,0,100);
        cameraControls.update();
        console.log("Camera Controls: Orbit");
    }

    if(Settings.cameraControlsType == CameraControlsType.firstPerson) {
        camera.fov = 50;
        camera.position.x = 0;
        camera.position.y = 1.5;
        camera.position.z = 15;
        camera.rotation.set(0, 180,0);
        console.log("Camera Controls: First Person");
    }

    if(Settings.cameraControlsType == CameraControlsType.lockControls) {
        camera.fov = 50;
        camera.position.x = 0;
        camera.position.y = 1.5;
        camera.position.z = 16;
        //camera.rotation.set(0, 30,0);
        console.log("Camera Controls: Lock Controls");
    }

    if(Settings.cameraControlsType == CameraControlsType.mobileControls) {
        camera.fov = 50;
        camera.position.x = 0;
        camera.position.y = 1.5;
        camera.position.z = 16;
        //camera.rotation.order = 'YXZ';
        //camera.rotation.set(0, 30,0);
        console.log("Camera Controls: Mobile Controls");
    }
}

export {camera, initializeCamera}