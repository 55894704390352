let settings: Settings;

enum CameraControlsType {
  orbitControls,
  firstPerson,
  lockControls,
  mobileControls,
}

class Settings {
  public cameraControlsType: CameraControlsType;
  public debugGallery: boolean; // Set true if want to use debug gallery
  public isMobileDevice: boolean;
  public isMobileDeviceDebug: boolean;
  public isLocalServer: boolean; // Set false before publish! Set true if testing at local server, so some files will be uploaded from local storage.
  public isUsingZip: boolean;

  public localGalleryFileFull: string;
  public remoteGalleryFileFull: string;
  public localGalleryFileOptimized: string;
  public remoteGalleryFileOptimized: string;

  public remoteGalleryFileZipFull: string;
  public remoteGalleryFileZipOptimized: string;

  public galleryFilePath: string;
  public galleryZipFilePath: string;
  public galleryZipFileName: string;

  constructor() {
    this.isMobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    this.isMobileDeviceDebug = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
    this.cameraControlsType = this.isMobileDevice ? CameraControlsType.mobileControls : CameraControlsType.lockControls;
    this.isLocalServer = location.hostname.toString().includes("localhost") || location.hostname.toString().includes("192.168.0");
    this.debugGallery = false;
    this.isUsingZip = false;

    this.remoteGalleryFileFull = "https://dl.dropbox.com/s/88tfoz1k5w16bdu/gallery_latest.json"; //= "https://theostudio.org/wp-content/uploads/2022/02/gallery%20-%20fixed.json";;
    this.remoteGalleryFileOptimized = "https://dl.dropbox.com/s/r5waf9o7cypkol7/gallery_mobile_latest.json"; //= "https://theostudio.org/wp-content/uploads/2022/02/gallery_optimized_x2.json";
    this.localGalleryFileFull = "/local/gallery.json";
    this.localGalleryFileOptimized = "/local/gallery_mobile.json";
    this.galleryFilePath = this.isLocalServer
      ? this.isMobileDevice
        ? this.localGalleryFileOptimized
        : this.localGalleryFileFull
      : this.isMobileDevice
      ? this.remoteGalleryFileOptimized
      : this.remoteGalleryFileFull;

    this.remoteGalleryFileZipFull = "https://dl.dropbox.com/s/br6l4uwm8ltegej/gallery%20-%20fixed.zip";
    this.remoteGalleryFileZipOptimized = "https://dl.dropbox.com/s/czcb2l2ltzazw60/gallery_optimized_x2.zip";
    this.galleryZipFilePath = this.isMobileDevice ? this.remoteGalleryFileZipOptimized : this.remoteGalleryFileZipFull;
    this.galleryZipFileName = this.isMobileDevice ? "gallery_optimized_x2.json" : "gallery - fixed.json";
    //console.log("this.galleryFilePath" + this.galleryFilePath);
  }
}

function initializeSettings() {
  settings = new Settings();
}

initializeSettings();

export { settings as Settings, CameraControlsType };
