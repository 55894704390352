import {Settings} from "../../../settings";

let hintDesktop : Element;
let hintMobile : Element;

let hintDesktopIsVisible : boolean;

function initializeHints(){
    hintDesktop = document.getElementsByClassName("hint-desktop")[0];
    hintMobile = document.getElementsByClassName("hint-mobile")[0];

    if(Settings.isMobileDevice) {
        hintDesktop.setAttribute("style", "display: none;");
        hintMobile.setAttribute("style", "display: block;");
    }
    else {
        hintDesktop.setAttribute("style", "display: block;");
        hintMobile.setAttribute("style", "display: none;");
        hintDesktopIsVisible = true;
        document.addEventListener( 'keyup', toggleDesktopHint);
    }
}

function toggleDesktopHint(event) {
    if(!Settings.isMobileDevice) {
        if(event.code !== "Space")
            return;

        if(hintDesktopIsVisible)
            hintDesktop.setAttribute("style", "display: none;");
        else
            hintDesktop.setAttribute("style", "display: block;");

        hintDesktopIsVisible = !hintDesktopIsVisible;
    }
}

export {initializeHints}