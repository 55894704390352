import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { FirstPersonControls } from "three/examples/jsm/controls/FirstPersonControls";
import { canvas } from "../canvas";
import { camera } from "../camera";
import { Settings, CameraControlsType } from "../../../settings";
import { PointerLockControls } from "./pointerLookControls";
import { scene } from "../scene";
import { MobileControls } from "./mobileControls";
import { basicEvents } from "../../gallery/events/basicEvents";

let cameraControls: any = undefined;

function initializeCameraControls() {
  document.addEventListener(basicEvents.galleryLoadedEvent.type, () => {
    // Orbit Controls
    if (Settings.cameraControlsType == CameraControlsType.orbitControls) {
      let orbitControls = new OrbitControls(camera, canvas);
      orbitControls.enableDamping = true;
      cameraControls = orbitControls;
    }

    // First Person
    if (Settings.cameraControlsType == CameraControlsType.firstPerson) {
      let firstPersonControls = new FirstPersonControls(camera, document.body);
      firstPersonControls.movementSpeed = 1.5;
      firstPersonControls.lookSpeed = 0.3;
      firstPersonControls.lookVertical = true;
      firstPersonControls.constrainVertical = true;
      firstPersonControls.verticalMin = 1.0;
      firstPersonControls.verticalMax = 2.0;
      firstPersonControls.mouseDragOn = true;

      cameraControls = firstPersonControls;
    }

    // Lock Controls
    if (Settings.cameraControlsType == CameraControlsType.lockControls) {
      cameraControls = new PointerLockControls(camera, scene);
    }

    //Mobile Controls
    if (Settings.cameraControlsType === CameraControlsType.mobileControls) {
      cameraControls = new MobileControls();
      cameraControls.initializeJoystick();
    }
  });
}

export { cameraControls, initializeCameraControls };
