import * as THREE from "three";
import { CameraControlsSettings } from "../../components/base/controls/cameraControlsBase";
import { basicEvents } from "../../components/gallery/events/basicEvents";
const nipplejs = require("nipplejs");
import { BaseModel } from "../../components/gallery/model/BaseModel";
import { Settings } from "../../settings";

let isJoystickMoving = false;

function initJoystick() {
  // create element
  let d = document.createElement("DIV");
  d.setAttribute("id", "np");
  d.classList.add("centered");
  document.querySelector("body").appendChild(d);
}

let moveData: any = "";

// create standard NipplesJS joystick
function createJoystick() {
  initJoystick();
  let options = {
    mode: "static",
    zone: document.getElementById("np"),
    color: "#ffffff00",
  };

  let manager = nipplejs.create(options);
  BaseModel.joystickManager = manager;

  bindNipple();
  // Resized should be forced to have correct nipple behaviour
  window.dispatchEvent(new Event("resize"));

  function bindNipple() {
    manager.on("move", function (evt, data) {
      moveData = data;
      isJoystickMoving = true;
    });
    manager.on("end", function (evt, data) {
      moveData = "";
      isJoystickMoving = false;
    });
  }

  update();
}

async function getJoystickCameraSettings(camera: THREE.Camera) {
  if (moveData == "") return;

  //console.log("Move Data: " + JSON.stringify(moveData.vector));

  let moveVector = moveData.vector;
  let moveForward = false;
  let moveBackward = false;
  let moveLeft = false;
  let moveRight = false;
  let speedBoosterMultiplier = 2;
  let currentSpeedBooster = 1;
  let speed = 1.5;
  //console.log("Speed: " + speed + "; navigator.userAgent: " + navigator.userAgent);

  if (Math.abs(moveVector.x) > Math.abs(moveVector.y)) {
    if (moveVector.x > 0) moveRight = true;
    else moveLeft = true;

    currentSpeedBooster += Math.abs(moveVector.x) * speedBoosterMultiplier;
  } else {
    if (moveVector.y > 0) moveForward = true;
    else moveBackward = true;

    currentSpeedBooster += Math.abs(moveVector.y) * speedBoosterMultiplier;
  }

  return new CameraControlsSettings(currentSpeedBooster, speed, moveForward, moveBackward, moveLeft, moveRight);
}

function update() {
  window.requestAnimationFrame(update);

  if (!isJoystickMoving) return;

  document.dispatchEvent(basicEvents.onControlsChanged);
}

export { createJoystick, getJoystickCameraSettings, isJoystickMoving };
