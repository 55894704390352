import * as THREE from "three";
import { Object3D } from "three";
import { scene } from "./scene";
import { camera } from "./camera";
import { BaseModel } from "../gallery/model/BaseModel";
import { basicEvents } from "../gallery/events/basicEvents";
import { CameraControlsType, Settings } from "../../settings";
import { cameraControls } from "./controls/cameraControls";

const mouse = new THREE.Vector2();
let lastMove = Date.now();
let raycaster = new THREE.Raycaster();
raycaster.far = 10;
let direction = new THREE.Vector3();

function initilizeRaycaster() {
  if (!Settings.isMobileDevice) document.addEventListener("mousemove", onMouseMove);
  else if (Settings.isMobileDevice && Settings.cameraControlsType === CameraControlsType.mobileControls) {
    document.addEventListener("touchstart", onTouchStart);
    document.addEventListener("touchmove", onTouchMove);
    document.addEventListener("touchend", onTouchEnd);
  }
}

function onMouseMove(event) {
  // calculate mouse position in normalized device coordinates
  // (-1 to +1) for both components

  mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;
  raycast();
  //console.log(`Mouse moved with values: X: ${mouse.x}; Y: ${mouse.y}`);
}

function onTouchStart(event: TouchEvent) {
  if (!cameraControls) return;

  mouse.x = (event.touches[0].clientX / window.innerWidth) * 2 - 1;
  mouse.y = -(event.touches[0].clientY / window.innerHeight) * 2 + 1;
  cameraControls.isTapDetected = true;
  //console.log(`Touch Start with values: X: ${event.touches[0].clientX}; Y: ${event.touches[0].clientY}`);
  //console.log(`Touch Start with mouse values: X: ${mouse.x}; Y: ${mouse.y}`);
}

function onTouchMove(event: TouchEvent) {
  if (!cameraControls) return;
  cameraControls.isTapDetected = false;
  //console.log(`Touch Move with values: X: ${event.touches[0].clientX}; Y: ${event.touches[0].clientY}`);
}

function onTouchEnd(event: TouchEvent) {
  if (!cameraControls) return;
  raycast();
  cameraControls.isTapDetected = false;
}

function raycast() {
  if (!cameraControls) return;
  if (Date.now() - lastMove < 31)
    // 32 frames a second
    return;
  else lastMove = Date.now();

  //Refresh properties
  BaseModel.raycastResults = [];

  if (Settings.cameraControlsType === CameraControlsType.firstPerson || Settings.cameraControlsType === CameraControlsType.orbitControls)
    raycaster.setFromCamera(mouse, camera);
  else if (Settings.cameraControlsType === CameraControlsType.mobileControls && !cameraControls.isBusy && cameraControls.isTapDetected)
    raycaster.setFromCamera(mouse, camera);
  else if (Settings.cameraControlsType === CameraControlsType.lockControls) {
    camera.getWorldDirection(direction);
    let forward: THREE.Vector3 = direction.clone();
    raycaster.set(camera.position, forward);
  } else return;

  let intersects: Array<THREE.Intersection<Object3D>> = raycaster.intersectObjects(scene.children);
  //intersects = intersects.sort((a, b) => a.distance - b.distance);
  BaseModel.raycastResults = intersects;
  //intersects.forEach((intersect) => console.log(intersect.object.name));

  if (intersects.length > 0) document.dispatchEvent(basicEvents.hitSomething);

  //DEBUG
  //console.log("Intersects length: " + intersects.length);
}

export { raycast, initilizeRaycaster };
