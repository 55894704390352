import { createJoystick, getJoystickCameraSettings, isJoystickMoving } from "../../../plugins/joystick/joystick";
import { handleCameraMovement } from "./cameraControlsBase";
import * as THREE from "three";
import { camera } from "../camera";
import { cameraControls } from "./cameraControls";
import { Quaternion } from "three";
import { BaseModel } from "../../gallery/model/BaseModel";
import { basicEvents } from "../../gallery/events/basicEvents";

class MobileControls {
  public controls;
  public isBusy: boolean;
  public isRotating: boolean;
  public isTapDetected: boolean;
  public moveData: any;
  public moveDataBuffer: any;
  private cameraTargetQuaternion: THREE.Quaternion;

  constructor() {
    this.isBusy = false;
    this.isTapDetected = false;
    this.isRotating = false;
  }

  public initializeJoystick() {
    createJoystick();
    BaseModel.joystickManager.on("start", function (evt, data) {
      document.dispatchEvent(basicEvents.startMovement);
      cameraControls.moveDataBuffer = "";
      //console.log("Move Data onStart: " + JSON.stringify(data.vector));
    });
    BaseModel.joystickManager.on("move", function (evt, data) {
      cameraControls.moveData = data;
      //console.log("Move Data onMove: " + JSON.stringify(data.vector));
    });
    BaseModel.joystickManager.on("end", function (evt, data) {
      document.dispatchEvent(basicEvents.endMovement);
      cameraControls.moveData = "";
      //console.log("Move Data: " + JSON.stringify(data.vector));
    });
    //Camera Rotation
    document.addEventListener("touchstart", (event: TouchEvent) => this.touchStart(event));
    document.addEventListener("touchmove", (event: TouchEvent) => this.touchMove(event));
    this.cameraTargetQuaternion = camera.quaternion;
  }

  public async update(camera: THREE.Camera) {
    if (!cameraControls) return;
    if (this.isBusy) return;
    await this.updateControls(camera);
  }

  private async updateControls(camera: THREE.Camera) {
    if (this.cameraTargetQuaternion) camera.quaternion.slerp(this.cameraTargetQuaternion, BaseModel.clockDelta * 5);

    let cameraControlsSettings = await getJoystickCameraSettings(camera);
    this.rotateCameraWithJoystick();

    //if(!cameraControls.isRotating)
    await handleCameraMovement(cameraControlsSettings);
  }

  private async rotateCameraWithJoystick() {
    if (cameraControls.moveData === "" || cameraControls.moveData === undefined) return;

    let vector: THREE.Vector2 = cameraControls.moveData.vector;
    let angle = (Math.atan2(vector.y - 1, vector.x) * 180) / Math.PI;
    let bufferedRotation;
    if (angle < -5 && angle > -45) {
      let percentage = (Math.abs(angle) - 5) / (45 - 5);
      let multiplier = 1 + percentage;
      let speed = multiplier < 1.5 ? 5 : 8;
      bufferedRotation = camera.quaternion.clone();
      camera.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), THREE.MathUtils.degToRad(-speed * multiplier));
      camera.updateMatrixWorld();
      this.cameraTargetQuaternion = camera.quaternion.clone();
      camera.quaternion.copy(bufferedRotation);
    }
    if (angle > -175 && angle < -135) {
      let percentage = (angle - 135) / (-175 - 135);
      let multiplier = 1 + (1 - Math.abs(percentage));
      let speed = multiplier < 1.5 ? 5 : 8;
      bufferedRotation = camera.quaternion.clone();
      camera.rotateOnWorldAxis(new THREE.Vector3(0, 1, 0), THREE.MathUtils.degToRad(speed * multiplier));
      camera.updateMatrixWorld();
      this.cameraTargetQuaternion = camera.quaternion.clone();
      camera.quaternion.copy(bufferedRotation);
    }

    cameraControls.moveDataBuffer = cameraControls.moveData;
    cameraControls.isRotating = true;
  }

  private touchPos: THREE.Vector2 = new THREE.Vector2();
  private prevTouchPos: THREE.Vector2 = new THREE.Vector2();

  async touchStart(event: TouchEvent) {
    if (isJoystickMoving || !cameraControls || cameraControls.isBusy) return;

    this.touchPos = new THREE.Vector2();
    this.prevTouchPos = new THREE.Vector2();
  }

  private _euler = new THREE.Euler(0, 0, 0, "YXZ");
  private _PI_2 = Math.PI / 2;
  private minPolarAngle = 0; // radians
  private maxPolarAngle = Math.PI; // radians

  async touchMove(event: TouchEvent) {
    if (isJoystickMoving || !cameraControls || cameraControls.isBusy) return;

    this.prevTouchPos = this.touchPos.clone();
    this.touchPos.set(event.touches[0].clientX, event.touches[0].clientY);

    if (this.prevTouchPos.x === 0 && this.prevTouchPos.y === 0) return;

    let touch = this.touchPos.clone().add(this.prevTouchPos.clone().multiplyScalar(-1));
    touch.x = -touch.x;
    touch.y = -touch.y;

    this._euler.setFromQuaternion(camera.quaternion);

    this._euler.y -= touch.x * 0.002;
    this._euler.x -= touch.y * 0.002;
    this._euler.x = Math.max(this._PI_2 - this.maxPolarAngle, Math.min(this._PI_2 - this.minPolarAngle, this._euler.x));
    camera.quaternion.setFromEuler(this._euler);
    this.cameraTargetQuaternion = camera.quaternion;
    //BaseModel.targetRotation.setFromEuler(_euler);
    document.dispatchEvent(basicEvents.onControlsChanged);

    console.log("touchMove");
  }
}

export { MobileControls };
