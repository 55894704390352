import "./style.css";
import "./plugins/joystick/joystick.css";
// import './assets/fonts/FiorinaGrande-LightItalic.otf'; // import from server instead. Some why that import doesn't work as expected.
import * as THREE from "three";
import { scene } from "./components/base/scene";
import { renderer } from "./components/base/renderer";
import { addListeners } from "./components/base/windowEvents";
import { camera, initializeCamera } from "./components/base/camera";
import { initilizeRaycaster, raycast } from "./components/base/raycaster";
import { cameraControls, initializeCameraControls } from "./components/base/controls/cameraControls";
import { Settings, CameraControlsType } from "./settings";
import { loadGallery } from "./components/gallery/controllers/galleryLoader";
import { loadColliders } from "./components/gallery/controllers/collidersLoader";
import { initializeInteractions } from "./components/gallery/controllers/interactionsController";
import { initializeSideBar } from "./components/gallery/view/sidebar";
import { initializeHints } from "./components/gallery/view/hints";
import { initializeCameraTransformUpdater } from "./components/gallery/controllers/cameraTransformUpdater";
import { basicEvents } from "./components/gallery/events/basicEvents";
import { BaseModel } from "./components/gallery/model/BaseModel";

const clock = new THREE.Clock();

let allowUpdate = true;

/**
 * Initializing
 */
(function () {
  initializeCameraControls();
  initializeCamera();
  addListeners();
  initilizeRaycaster();
  loadGallery();
  //initializePlayer();
  loadColliders();
  initializeInteractions();
  initializeSideBar();
  initializeHints();
  initializeCameraTransformUpdater();
  //initializeFrustumCheck();
  //initializeLODController();
  //initializeImageLoader();

  document.addEventListener(basicEvents.requestRenderer.type, () => {
    allowUpdate = true;
  });
  document.addEventListener(basicEvents.onControlsChanged.type, () => {
    allowUpdate = true;
  });

  //Because js will be loaded later than dom, so we should show overlay & disable that only when js will be loaded.
  document.getElementById("overlay").style.display = "none";
})();

/**
 * Animate / Update
 */
const update = () => {
  window.requestAnimationFrame(update);
  BaseModel.clockDelta = BaseModel.clock.getDelta();

  if (!allowUpdate) return;

  updateControls();
  render();
  allowUpdate = false;
};

update();

function updateControls() {
  // Update Orbital Controls
  if (Settings.cameraControlsType == CameraControlsType.orbitControls || Settings.cameraControlsType == CameraControlsType.lockControls)
    cameraControls?.update();
  if (Settings.cameraControlsType == CameraControlsType.mobileControls) cameraControls?.update(camera);
  if (Settings.cameraControlsType == CameraControlsType.firstPerson) cameraControls?.update(clock.getDelta());

  render();
}

function render() {
  // Render
  renderer.render(scene, camera);
}
