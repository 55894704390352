import {basicEvents} from "../events/basicEvents";
import {BaseModel} from "../model/BaseModel";
import {cameraControls} from "../../base/controls/cameraControls";
import {CameraControlsType, Settings} from "../../../settings";
import * as THREE from "three";
import {scene} from "../../base/scene";

let aimIcon : HTMLElement;

function initializeInteractions() {
    if(Settings.isMobileDevice) {
        document.addEventListener(basicEvents.hitSomething.type, updateInteractionsMobile);
        document.addEventListener(basicEvents.openSideBar.type, () => cameraControls.isBusy = true);
        document.addEventListener(basicEvents.closeSideBar.type, () => cameraControls.isBusy = false);
    }
    else if (!Settings.isMobileDevice)
    {
        document.addEventListener(basicEvents.hitSomething.type, updateInteractions);
        document.addEventListener('mousedown', clickOnPainting, false);
    }

    initializeAim();
}

function initializeAim() {
    aimIcon = document.getElementById("aim");
    if(Settings.isMobileDevice) {
        aimIcon.style.display = "none";
    } else {
        if (!aimIcon.classList.contains("aim-animation-default"))
            aimIcon.classList.add("aim-animation-default");
    }
}

function updateInteractions() {
    if(BaseModel.raycastResults.length === 0 || cameraControls.isBusy)
        return;

    let index = BaseModel.raycastResults[0].object.name.includes("Colliderwall") ? 1 : 0;
    if(BaseModel.raycastResults[index] === undefined)
        return;
    let hitPicture = BaseModel.raycastResults[index].object.name.includes("Picture") ? BaseModel.raycastResults[index] : undefined;

    //for(let i=0; i<BaseModel.raycastResults.length; i++)
    //    console.log(`Hit Object ${i} with name: ${BaseModel.raycastResults[i].object.name}`);

    if(hitPicture === undefined) {
        if(aimIcon.classList.contains("aim-animation-target")) {
            aimIcon.classList.remove("aim-animation-target");
            aimIcon.classList.add("aim-animation-default");
        }
        BaseModel.isRaycastHitPicture = false;
        return;
    }

    if(aimIcon.classList.contains("aim-animation-default")) {
        aimIcon.classList.remove("aim-animation-default");
        aimIcon.classList.add("aim-animation-target");
    }

    BaseModel.isRaycastHitPicture = true;
    BaseModel.raycastHitPicture = hitPicture.object;
}

function updateInteractionsMobile() {
    if(BaseModel.raycastResults.length === 0 || cameraControls.isBusy)
        return;

    let index = BaseModel.raycastResults[0].object.name.includes("Colliderwall") ? 1 : 0;
    if(BaseModel.raycastResults[index] === undefined)
        return;
    let hitPicture = BaseModel.raycastResults[index].object.name.includes("Picture") ? BaseModel.raycastResults[index] : undefined;

    //for(let i=0; i<BaseModel.raycastResults.length; i++)
    //   console.log(`Hit Object ${i} with name: ${BaseModel.raycastResults[i].object.name}`);

    if(hitPicture === undefined)
        return;

    BaseModel.raycastHitPicture = hitPicture.object;
    document.dispatchEvent(basicEvents.openSideBar);
    document.dispatchEvent(basicEvents.updateSideBarData);
}

function clickOnPainting() {
    if(!BaseModel.isRaycastHitPicture)
        return;

    document.dispatchEvent(basicEvents.openSideBar);
    document.dispatchEvent(basicEvents.updateSideBarData);
    //console.log("BaseModel.raycastHitPicture " + BaseModel.raycastHitPicture.name);

    if(Settings.cameraControlsType === CameraControlsType.lockControls)
        cameraControls.controls.unlock();
}

export {initializeInteractions}