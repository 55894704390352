import ZipLoader from 'zip-loader';
import {basicEvents} from "../events/basicEvents";
import {BaseModel} from "../model/BaseModel";
import {Settings} from "../../../settings";

function loadZip() {
    let loader = new ZipLoader( Settings.galleryZipFilePath );
    let loaderProgressContainer = document.getElementById("loader-progress-container");
    let loaderText = document.getElementById("loader-progress-text");

    loader.on( 'progress', function ( event ) {
        loaderProgressContainer.style.display = "block";
        loaderText.textContent = 'Loaded ' + (event.loaded/event.total * 100).toFixed(0) + '%';
        //console.log( 'loading', event.loaded, event.total );
    } );

    loader.on( 'load', function ( event ) {
        //console.log( 'loaded!' );
        //console.log( loader.files[0] );
        let data = loader.extractAsJSON(Settings.galleryZipFileName);
        //let data = loader.extractAsText('gallery - fixed.json' );
        BaseModel.galleryJson = data;
        document.dispatchEvent(basicEvents.zipLoaded);
        //console.log( json );
    } );

    loader.load();
}

export {loadZip}