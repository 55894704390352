import { basicEvents } from "../events/basicEvents";
import { CameraControlsType, Settings } from "../../../settings";
import { cameraControls } from "../../base/controls/cameraControls";
import * as THREE from "three";
import { BaseModel } from "../model/BaseModel";
import { Artwork } from "../model/ArtworksModel";

const artworksJson: Array<Artwork> = require("../../../assets/artworks_database.json");

let sideBar = document.getElementById("side-bar-block");
let sideBarDescriptionBlock = document.getElementById("side-bar-description-block");
let sideBarHeaderBlock = document.getElementById("side-bar-header-block");
let sideBarFooter = document.getElementById("side-bar-footer-block");
let closeSideBarButton = document.getElementById("close-side-bar-button");
let sideBarImage = document.getElementById("side-bar-image") as HTMLImageElement;
let sideBarName = document.getElementById("side-bar-name");
let sideBarAuthor = document.getElementById("side-bar-author");
let sideBarMaterial = document.getElementById("side-bar-material");
let sideBarSize = document.getElementById("side-bar-size");
let sideBarYear = document.getElementById("side-bar-year");
let sideBarDescription = document.getElementById("side-bar-description");
let sidebarLink = document.getElementById("side-bar-link");

function initializeSideBar() {
  updateSideBarProperties();

  hideSideBar();
  closeSideBarButton.addEventListener("click", hideSideBar);
  closeSideBarButton.addEventListener("click", () => document.dispatchEvent(basicEvents.closeSideBar));
  document.addEventListener(basicEvents.openSideBar.type, showSideBar);
  document.addEventListener(basicEvents.updateSideBarData.type, updateSideBarData);
}

let currentArtworkCache: Artwork = undefined;

function updateSideBarData() {
  if (BaseModel.raycastHitPicture === undefined) return;

  let currentArtwork = artworksJson.find((artwork) => artwork.artwork_picture_id === BaseModel.raycastHitPicture.name);

  currentArtworkCache = currentArtwork;
  // Update Picture
  sideBarImage.src = getImageUrl(currentArtwork);
  // Update Image Size
  forceUpdateImageSize(currentArtwork);
  //console.log(`Ratio: ${ratio}; height: ${height} BaseModel.raycastHitPicture.scale: ${JSON.stringify(worldScale)}; regexMatch: ${regexMatch.toString()}`);
  // Update Other Properties
  sideBarName.innerText = currentArtwork.artwork_name;
  sideBarAuthor.innerText = currentArtwork.artwork_author;
  sideBarMaterial.innerText = currentArtwork.artwork_material;
  sideBarSize.innerText = currentArtwork.artwork_size;
  sideBarYear.innerText = currentArtwork.artwork_year;
  sideBarDescription.innerText = currentArtwork.artwork_description;
  sidebarLink.setAttribute("href", currentArtwork.artwork_url);
  // Fix some images
  sideBarImage.style.transform = currentArtwork.artwork_id === "34" || currentArtwork.artwork_id === "35" ? "scaleX(-1)" : "";

  updateSideBarProperties();
  forceUpdateImageSize(currentArtwork);
}

window.addEventListener("resize", () => {
  updateSideBarProperties();
  forceUpdateImageSize(currentArtworkCache);
});

function forceUpdateImageSize(currentArtwork: Artwork) {
  if (currentArtwork === undefined || currentArtwork.artwork_image_size === undefined) return;

  let size = currentArtwork.artwork_image_size === "" ? currentArtwork.artwork_size : currentArtwork.artwork_image_size;
  let regexMatch = size.match(/(\d)+/g);
  if (regexMatch != undefined && regexMatch.length === 2) {
    let ratio = parseFloat(regexMatch[0]) / parseFloat(regexMatch[1]);
    sideBarImage.height = sideBarImage.offsetWidth / ratio;
    //TODO. Fix proportions in artworks_database.json based on real image data instead of cm
    //console.log("Set height to value: " + sideBarImage.height + "; sideBarImage.offsetWidth: " + sideBarImage.offsetWidth);
  }
}

function updateSideBarProperties() {
  let extraHeight = Settings.isMobileDevice && sideBarDescription.innerText !== "" ? sideBarHeaderBlock.clientHeight : 0;
  let styleData = Settings.isMobileDevice
    ? "margin-bottom: " + sideBarFooter.clientHeight.toString() + "px;" + "min-height: " + (sideBarHeaderBlock.clientHeight + extraHeight).toString() + "px;"
    : "margin-bottom: " + sideBarFooter.clientHeight.toString() + "px;";

  sideBarDescriptionBlock.setAttribute("style", styleData);
}

function showSideBar() {
  sideBar.setAttribute("style", "display: block;");
  sideBar.classList.add("side-bar-slide-in");
  sideBar.classList.remove("side-bar-slide-out");
}

function hideSideBar() {
  setTimeout(() => sideBar.setAttribute("style", "display: none;"), 1000);
  sideBar.classList.add("side-bar-slide-out");
  sideBar.classList.remove("side-bar-slide-in");

  if (Settings.cameraControlsType == CameraControlsType.lockControls) cameraControls?.controls?.lock();
}

function getImageUrl(currentArtwork: Artwork): string {
  let isUsingLocalFile = currentArtwork.artwork_isUsingLocalFile;
  // console.log("currentArtwork.artwork_isUsingLocalFile: " + currentArtwork.artwork_isUsingLocalFile);
  // console.log("isUsingLocalFile == true: " + (isUsingLocalFile == true));
  // console.log("isUsingLocalFile == true: " + (isUsingLocalFile === true));
  // console.log("isUsingLocalFile == true: " + (isUsingLocalFile.toString() == 'true')); / works
  // console.log("isUsingLocalFile == true: " + (isUsingLocalFile.toString() === 'true')); / works
  if (isUsingLocalFile.toString() === "true") {
    return "/artworks/" + currentArtwork.artwork_image_name + ".jpg";
  } else {
    let pictureMesh = BaseModel.raycastHitPicture as THREE.Mesh;
    let pictureMaterial = pictureMesh.material as THREE.MeshBasicMaterial;
    let pictureImage: HTMLImageElement = pictureMaterial.map.image;
    return pictureImage.src;
  }
}

export { initializeSideBar };
