import * as THREE from "three";
import { PointerLockControls as PointerLockControlsCore } from "three/examples/jsm/controls/PointerLockControls";
import { isPositionValid, isPositionValidRaycast } from "../../gallery/controllers/collisionController";
import { camera } from "../camera";
import { BaseModel } from "../../gallery/model/BaseModel";
import { CameraControlsSettings, handleCameraMovement } from "./cameraControlsBase";
import { basicEvents } from "../../gallery/events/basicEvents";

let moveForward = false;
let moveBackward = false;
let moveLeft = false;
let moveRight = false;
let prevTime;
let direction = new THREE.Vector3();
let velocity = new THREE.Vector3();
let controls;

let speed = 1.75;
let minSpeedBooster = 2;
let currentSpeedBooster = minSpeedBooster;
let maxSpeedBooster = 4;

class PointerLockControls {
  public controls: PointerLockControlsCore;

  public constructor(camera: THREE.Camera, scene: THREE.Scene) {
    controls = new PointerLockControlsCore(camera, document.body);
    this.controls = controls;
    scene.add(controls.getObject());

    document.addEventListener("keydown", onKeyDown);
    document.addEventListener("keyup", onKeyUp);
    this.controls.addEventListener("change", () => document.dispatchEvent(basicEvents.onControlsChanged));
    //this.controls.isLocked = false;
    update();
  }

  public async update() {
    //updateControlsVelocity();
    await updateControls();
  }
}

async function updateControls() {
  if (controls.isLocked === false) return;

  let cameraControlsSettings = new CameraControlsSettings(currentSpeedBooster, speed, moveForward, moveBackward, moveLeft, moveRight);
  await handleCameraMovement(cameraControlsSettings);
}

// async function updateControlsVelocity() {
//     const time = performance.now();
//
//     if ( controls.isLocked === true ) {
//         const delta = ( time - prevTime ) / 1000;
//         velocity.x -= velocity.x * 10.0 * delta;
//         velocity.z -= velocity.z * 10.0 * delta;
//
//         direction.z = Number( moveForward ) - Number( moveBackward );
//         direction.x = Number( moveRight ) - Number( moveLeft );
//         direction.normalize(); // this ensures consistent movements in all directions
//
//         if ( moveForward || moveBackward ) velocity.z -= direction.z * 6.0 * currentSpeedBooster * delta;
//         if ( moveLeft || moveRight ) velocity.x -= direction.x * 6.0 * currentSpeedBooster * delta;
//
//         controls.moveRight( - velocity.x * delta );
//         controls.moveForward( - velocity.z * delta );
//
//         // Collision detection
//         let isValid = await isPositionValidRaycast(camera.position, BaseModel.colliderObjects);//isPositionValid(BaseModel.playerMesh, camera.position);
//         console.log("Is new position valid: " + isValid);
//
//         if(!isValid) {
//             controls.moveRight(velocity.x * delta);
//             controls.moveForward(velocity.z * delta);
//         }
//     }
//
//     prevTime = time;
// }

const onKeyDown = function (event) {
  if (event.code == "ArrowUp" || event.code == "KeyW") {
    document.dispatchEvent(basicEvents.startMovement);
    moveForward = true;
  }
  if (event.code == "ArrowLeft" || event.code == "KeyA") {
    document.dispatchEvent(basicEvents.startMovement);
    moveLeft = true;
  }
  if (event.code == "ArrowDown" || event.code == "KeyS") {
    document.dispatchEvent(basicEvents.startMovement);
    moveBackward = true;
  }
  if (event.code == "ArrowRight" || event.code == "KeyD") {
    document.dispatchEvent(basicEvents.startMovement);
    moveRight = true;
  }
  if (event.code == "Space") !controls.isLocked ? controls.lock() : controls.unlock();
  if (event.code == "ShiftLeft") currentSpeedBooster = maxSpeedBooster;
};

const onKeyUp = function (event) {
  if (event.code == "ArrowUp" || event.code == "KeyW") {
    document.dispatchEvent(basicEvents.endMovement);
    moveForward = false;
  }
  if (event.code == "ArrowLeft" || event.code == "KeyA") {
    document.dispatchEvent(basicEvents.endMovement);
    moveLeft = false;
  }
  if (event.code == "ArrowDown" || event.code == "KeyS") {
    document.dispatchEvent(basicEvents.endMovement);
    moveBackward = false;
  }
  if (event.code == "ArrowRight" || event.code == "KeyD") {
    document.dispatchEvent(basicEvents.endMovement);
    moveRight = false;
  }
  if (event.code == "ShiftLeft") currentSpeedBooster = minSpeedBooster;
};

const update = () => {
  window.requestAnimationFrame(update);

  if (!moveLeft && !moveRight && !moveForward && !moveBackward) return;

  document.dispatchEvent(basicEvents.onControlsChanged);
};

export { PointerLockControls };
