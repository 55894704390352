/**
 * Renderer
 */
import * as THREE from "three";
import {canvas} from "./canvas";

let pixelRatio = window.devicePixelRatio;
let AA = true;
if (pixelRatio > 1) {
    AA = false
}

const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: AA,
    powerPreference: "high-performance"
});

renderer.setSize(window.innerWidth, window.innerHeight);
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
renderer.shadowMap.enabled = false;
//renderer.shadowMap.type = THREE.PCFSoftShadowMap; // default THREE.PCFShadowMap
//renderer.capabilities.maxCubemapSize = 512;

export {renderer}