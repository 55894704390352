import {BaseModel} from "../model/BaseModel";
import {basicEvents} from "../events/basicEvents";
import {scene} from "../../base/scene";

function loadColliders() {
    document.addEventListener(basicEvents.galleryLoadedEvent.type, loadGalleryCollider);
    generatePlayerCollider();
}

function loadGalleryCollider() {
    //Here we could set/load gallery collider
    const wallColliderMesh = scene.getObjectByName("steni");//scene.getObjectByName("Colliderwall");
    if(wallColliderMesh === undefined) {
        console.log("Collider Mesh couldn't be found");
        return;
    }
    BaseModel.colliderObjects.push(wallColliderMesh);
}

function generatePlayerCollider() {
    const player = BaseModel.playerMesh;
    if(player === undefined)
        return;
    player.geometry.computeBoundingBox();
    BaseModel.playerCollider.copy(player.geometry.boundingBox).applyMatrix4(player.matrixWorld);
}

export {loadColliders}