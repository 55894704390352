import * as THREE from "three";
import { Object3D } from "three";

class BaseModel {
  //Gallery
  galleryObject: THREE.Object3D;
  galleryJson: any;
  galleryPictures: Array<THREE.Object3D> = new Array<THREE.Object3D>();
  isGalleryLoaded: boolean = false;

  //Colliders
  playerCollider: THREE.Box3 = new THREE.Box3();
  colliders: Array<THREE.Box3> = [];

  //Meshes
  playerMesh: THREE.Mesh;

  //Objects
  colliderObjects: Array<THREE.Object3D> = [];

  //Raycasts
  raycastResults: Array<THREE.Intersection<Object3D>> = [];

  //Raycast Hits
  isRaycastHitPicture: boolean = false;
  raycastHitPicture: THREE.Object3D;

  //Target Values
  targetRotation: THREE.Quaternion = new THREE.Quaternion();
  targetPosition: THREE.Vector3 = new THREE.Vector3();

  //Joystick Manager
  joystickManager: any;

  //Clock
  clock: THREE.Clock = new THREE.Clock();
  clockDelta: number = 0;
}

let baseModel = new BaseModel();

export { baseModel as BaseModel };
