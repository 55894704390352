import {camera} from "./camera";
import {renderer} from "./renderer";
import {cameraControls} from "./controls/cameraControls";
import {Settings, CameraControlsType} from "../../settings";

// Update Window on resize
function addListeners() {
    window.addEventListener('resize', onWindowResize );
}

function onWindowResize() {
    // Update camera
    camera.aspect = window.innerWidth / window.innerHeight;
    camera.updateProjectionMatrix();

    // Update renderer
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));

    //Update First Person Camera
    if(Settings.cameraControlsType == CameraControlsType.firstPerson)
        cameraControls.handleResize();

    //console.log("Updated");
}

export {addListeners, onWindowResize}