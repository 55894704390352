class BasicEvents {
    galleryLoadedEvent: Event;
    hitSomething : Event;
    openSideBar : Event;
    closeSideBar : Event;
    updateSideBarData : Event;
    onControlsChanged : Event;
    requestRenderer : Event;
    startMovement : Event;
    endMovement : Event;
    zipLoaded : Event;

    constructor() {
        this.galleryLoadedEvent = new Event('galleryLoadedEvent');
        this.hitSomething = new Event('hitSomething');
        this.openSideBar = new Event('openSideBar');
        this.closeSideBar = new Event('closeSideBar');
        this.updateSideBarData = new Event('updateSideBarData');
        this.onControlsChanged = new Event('onControlsChanged')
        this.requestRenderer = new Event('requestRenderer');
        this.startMovement = new Event('startMovement');
        this.endMovement = new Event('endMovement');
        this.zipLoaded = new Event('zipLoaded');
    }
}

const basicEvents = new BasicEvents();

export {basicEvents}