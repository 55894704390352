import {BaseModel} from "../model/BaseModel";
import {camera} from "../../base/camera";
import * as THREE from "three";

const raycasterF = new THREE.Raycaster();
const raycasterB = new THREE.Raycaster();
const raycasterR = new THREE.Raycaster();
const raycasterL = new THREE.Raycaster();

raycasterF.far = 1;
raycasterB.far = 1;
raycasterR.far = 1;
raycasterL.far = 1;

raycasterF.layers.set(1);
raycasterB.layers.set(1);
raycasterR.layers.set(1);
raycasterL.layers.set(1);

/*------ Solution#1: Return previous position --------*/
function isPositionValid(mesh : THREE.Mesh, position : THREE.Vector3, colliders : Array<THREE.Box3>) : Promise<boolean> {
    let tempMesh = mesh.clone();
    tempMesh.position.set(position.x, position.y, position.z);
    let playerCollider = new THREE.Box3();
    playerCollider.copy( tempMesh.geometry.boundingBox ).applyMatrix4( tempMesh.matrixWorld );
    let intersections : Array<boolean> = new Array<boolean>();
    for(let i=0; i< colliders.length; i++)
        intersections.push(playerCollider.intersectsBox(colliders[i]));
    tempMesh.remove();
    let isValid = !intersections.find(value => value == true);
    return Promise.resolve(isValid);
}

/*------ Solution#2: Raycast: Return previous position --------*/
function isPositionValidRaycast(position : THREE.Vector3, objects : Array<THREE.Object3D>) : Promise<boolean> {

    let axis = new THREE.Vector3( 0, 1, 0 );
    let angle = Math.PI / 2;
    let dir = new THREE.Vector3();
    camera.getWorldDirection( dir );
    dir.setY(0);

    let forward : THREE.Vector3 = dir.clone();
    let back : THREE.Vector3 = dir.clone().multiplyScalar(-1);
    let right : THREE.Vector3 = dir.clone().applyAxisAngle( axis, angle );
    let left : THREE.Vector3 = dir.clone().multiplyScalar(-1).applyAxisAngle( axis, angle );

    raycasterF.set(position, forward);
    raycasterB.set(position, back);
    raycasterR.set(position, right);
    raycasterL.set(position, left);

    const intersectsF = raycasterF.intersectObjects( objects );
    const intersectsB = raycasterB.intersectObjects( objects );
    const intersectsR = raycasterR.intersectObjects( objects );
    const intersectsL = raycasterL.intersectObjects( objects );

    let anyIntersectF : boolean = intersectsF.find(intersect => intersect.distance < 0.35) !== undefined;
    let anyIntersectB : boolean= intersectsB.find(intersect => intersect.distance < 0.35) !== undefined;
    let anyIntersectR : boolean = intersectsR.find(intersect => intersect.distance < 0.35) !== undefined;
    let anyIntersectL : boolean= intersectsL.find(intersect => intersect.distance < 0.35) !== undefined;

    let isValid = !anyIntersectF && !anyIntersectB && !anyIntersectR && !anyIntersectL;
    return Promise.resolve(isValid);
}

export {isPositionValid, isPositionValidRaycast}