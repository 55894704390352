import { scene } from "../../base/scene";
import { basicEvents } from "../events/basicEvents";
import { Settings } from "../../../settings";
import * as THREE from "three";
import { BaseModel } from "../model/BaseModel";
import { loadZip } from "./zipLoader";
import Swiper, { EffectFade } from "swiper";
import "swiper/css/effect-fade";

const loader = new THREE.ObjectLoader();
let loaderProgressContainer: HTMLElement;
let loaderText: HTMLElement;
let loaderTextContainer: HTMLElement;
let totalBytes: number;
let swiper: Swiper;
let swiperInterval: NodeJS.Timer;
//let loaderBackground: HTMLElement;

function loadGallery() {
  initializeLayoutVariables();

  if (Settings.debugGallery) {
    initializeDebugGallery();
    return;
  }

  if (Settings.isUsingZip) {
    loadZip();
    document.addEventListener(basicEvents.zipLoaded.type, parseGallery);
  } else {
    //loadGalleryFromURL();
    loadGalleryFromUrlAsync();
  }
}

function initializeLayoutVariables() {
  loaderProgressContainer = document.getElementById("loader-progress-container");
  loaderText = document.getElementById("loader-progress-text");
  totalBytes = Settings.isMobileDevice ? 56311448 : 103617836;
  loaderTextContainer = document.getElementById("loader-text-container");
  const swiperElement = document.getElementById("swiper");
  if (!swiperElement) console.error("Swiper not found");
  const slideDelay = 2500;
  swiper = new Swiper(swiperElement, {
    modules: [EffectFade],
    effect: "fade",
    fadeEffect: {
      crossFade: true,
    },
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: {
      disableOnInteraction: false,
    },
    slidesPerView: 1,
    loop: true,
  });
  setTimeout(() => {
    swiperInterval = setInterval(() => {
      if (swiperElement.style.display == "none") clearInterval(swiperInterval);
      swiper.slideNext(1000);
    }, slideDelay);
  }, slideDelay);
  //loaderBackground = document.getElementById("loader-background");
  //loaderBackground.style.backgroundImage = "url(background.png)";
  //loaderBackground.style.backgroundPositionX = window.innerWidth > window.innerHeight ? "" : "53%";
}

function parseGallery() {
  let loaderProgressContainer = document.getElementById("loader-progress-container");

  loader.parse(BaseModel.galleryJson, function (obj) {
    // Add the loaded object to the scene
    onGalleryLoaded(obj);
    //Hide loader panel
    loaderProgressContainer.style.display = "none";
  });
}

function loadGalleryFromURL() {
  if (Settings.debugGallery) {
    initializeDebugGallery();
    return;
  }

  loader.load(
    // resource URL
    Settings.galleryFilePath,
    // onLoad callback
    onGalleryLoaded,

    // onProgress callback
    onProgress,

    // onError callback
    function (err) {
      console.error("An error happened: " + err);
    }
  );
}

async function loadGalleryFromUrlAsync() {
  if (Settings.debugGallery) {
    initializeDebugGallery();
    return;
  }

  const gallery = await loader.loadAsync(
    Settings.galleryFilePath,
    // onProgress callback
    onProgress
  );
  if (gallery === undefined) console.log("Gallery not loaded");
  onGalleryLoaded(gallery);
}

function onProgress(xhr) {
  let total: number = xhr.total === 0 ? totalBytes : xhr.total;
  let progress = (xhr.loaded / total) * 100;
  loaderProgressContainer.style.display = "block";
  loaderText.textContent = progress.toFixed(0) + "%";
  let alpha = 1.15 - (progress * 2) / 100;
  loaderTextContainer.style.backgroundColor = "rgba(255, 255, 255, " + alpha + ")";
  //console.log(`Total: ${xhr.total}; Loaded: ${xhr.loaded}`);
  //console.log( (xhr.loaded / xhr.total * 100) + '% loaded' );
}

function onGalleryLoaded(gallery: THREE.Object3D) {
  //Add gallery to scene
  scene.add(gallery);
  BaseModel.galleryObject = gallery;
  //Resize Gallery
  gallery.scale.set(0.001, 0.001, 0.001);
  //Disable matrix auto update
  gallery.matrixAutoUpdate = false;
  //gallery.children.forEach((obj) => (obj.matrixAutoUpdate = false));
  gallery.traverseVisible((object) => {
    object.matrixAutoUpdate = false;
    if (object.name.includes("Picture")) BaseModel.galleryPictures.push(object);
  });
  gallery.updateMatrix();
  //Set layers for walls
  let walls = gallery.getObjectByName("steni");
  walls.layers.set(1);
  // Dispatch event
  document.dispatchEvent(basicEvents.galleryLoadedEvent);
  document.dispatchEvent(basicEvents.requestRenderer);

  updateUi();
  //Tree was changed, so not need this step.
  updateTreeMaterials();
}

function updateUi() {
  //Disable swiper
  clearInterval(swiperInterval);
  //Enable HINTS
  document.getElementById("hints").style.display = "block";
  //Hide loader panel
  //loaderProgressContainer.style.display = "none";
  loaderProgressContainer.classList.add("fade-out");
  loaderProgressContainer.style.opacity = "1";

  for (let i = 0; i < loaderProgressContainer.children.length; i++) {
    setTimeout(() => ((loaderProgressContainer.children[i] as HTMLElement).style.display = "none"), 6000);
  }
  //(loaderProgressContainer.children[0] as HTMLElement).style.display = "none";
  //Disable body background
  document.body.style.backgroundColor = "transparent";
  setTimeout(() => (loaderProgressContainer.style.display = "none"), 6000);
  setTimeout(() => (loaderProgressContainer.style.zIndex = "1"), 3000); // 6000ms as we have from fade out animation
}

function updateTreeMaterials() {
  if (Settings.isMobileDevice) {
    return;
  }

  let tree01 = scene.children[0].children[2].children[0];
  if (tree01 != undefined) updateTreeMaterial(tree01);
  let tree02 = scene.children[0].children[3].children[0];
  if (tree02 != undefined) updateTreeMaterial(tree01);
}

function updateTreeMaterial(tree: THREE.Object3D) {
  let treeMesh = tree as THREE.Mesh;
  let treeMaterial = treeMesh.material[1] as THREE.MeshPhongMaterial;
  treeMaterial.map = null;
  treeMaterial.color = new THREE.Color("0xffffff");
  treeMaterial.needsUpdate = true;
}

function initializeDebugGallery() {
  let floorMaterial = new THREE.MeshBasicMaterial();
  let floorGeometry = new THREE.BoxGeometry(1, 1, 1);
  let floor = new THREE.Mesh(floorGeometry, floorMaterial);
  scene.add(floor);
  floor.material.color.setHex(-250);
  floor.scale.set(20, 0.01, 20);
  floor.position.set(0, 0.005, 0);

  let wallMaterial = new THREE.MeshBasicMaterial();
  let wallGeometry = new THREE.BoxGeometry(1, 1, 1);
  let wall = new THREE.Mesh(wallGeometry, wallMaterial);
  scene.add(wall);
  wall.material.color.setHex(100);
  wall.scale.set(5, 2, 0.5);
  wall.position.set(2, 1, 2);

  document.body.classList.add("gradient-background");

  console.log("Initialized debug gallery");
}

export { loadGallery };
