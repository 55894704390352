import * as THREE from "three";
import { camera } from "../camera";
import { isPositionValidRaycast } from "../../gallery/controllers/collisionController";
import { BaseModel } from "../../gallery/model/BaseModel";
import { Clock } from "three";
import { basicEvents } from "../../gallery/events/basicEvents";

class CameraControlsSettings {
  speed: number;
  speedBooster: number;
  moveForward: boolean;
  moveBackward: boolean;
  moveLeft: boolean;
  moveRight: boolean;

  constructor(speedBooster: number, speed: number, moveForward: boolean, moveBackward: boolean, moveLeft: boolean, moveRight: boolean) {
    this.speedBooster = speedBooster;
    this.speed = speed;
    this.moveForward = moveForward;
    this.moveBackward = moveBackward;
    this.moveLeft = moveLeft;
    this.moveRight = moveRight;
  }
}

async function handleCameraMovement(cameraControlsSettings: CameraControlsSettings) {
  if (cameraControlsSettings === undefined) return;

  let vector = new THREE.Vector3();
  let vectorZero = new THREE.Vector3();
  let currentSpeed = cameraControlsSettings.speed * cameraControlsSettings.speedBooster * BaseModel.clockDelta;
  let axis = new THREE.Vector3(0, 1, 0);
  let angle = Math.PI / 2;

  let dir = new THREE.Vector3();
  camera.getWorldDirection(dir);
  dir.setY(0);

  if (cameraControlsSettings.moveForward)
    vector = vector === vectorZero ? dir.multiplyScalar(currentSpeed) : vector.add(dir.multiplyScalar(currentSpeed)).divideScalar(2);
  if (cameraControlsSettings.moveBackward)
    vector = vector === vectorZero ? dir.multiplyScalar(-currentSpeed) : vector.add(dir.multiplyScalar(-currentSpeed)).divideScalar(2);
  if (cameraControlsSettings.moveLeft)
    vector =
      vector === vectorZero
        ? dir.multiplyScalar(currentSpeed).applyAxisAngle(axis, angle)
        : vector.add(dir.multiplyScalar(currentSpeed).applyAxisAngle(axis, angle)).divideScalar(2);
  if (cameraControlsSettings.moveRight)
    vector =
      vector === vectorZero
        ? dir.multiplyScalar(-currentSpeed).applyAxisAngle(axis, angle)
        : vector.add(dir.multiplyScalar(-currentSpeed).applyAxisAngle(axis, angle)).divideScalar(2);

  let wantedPosition = camera.position.clone();
  wantedPosition.add(vector);
  let isValid = await isPositionValidRaycast(wantedPosition, BaseModel.colliderObjects); //await isPositionValid(BaseModel.playerMesh, wantedPosition);

  if (!isValid) return;

  wantedPosition = camera.position.lerp(wantedPosition, BaseModel.clockDelta * 5);
  camera.position.add(vector);
}

export { handleCameraMovement, CameraControlsSettings };
