import {camera} from "../../base/camera";
import {BaseModel} from "../model/BaseModel";
import {Quaternion} from "three";

function initializeCameraTransformUpdater() {
    BaseModel.targetRotation.set(camera.quaternion.x, camera.quaternion.y, camera.quaternion.z, camera.quaternion.w);
    update();
}

function update() {
    //camera.quaternion.slerp(BaseModel.targetRotation, 0.7);

    window.requestAnimationFrame(update);
}

export {initializeCameraTransformUpdater}